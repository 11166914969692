.confirm-modal{
    button {
        min-width: 50px;
        .loader {
            display: none;
        }
    }
      
    button:disabled {
        .confirm-text {
            display: none;
        }
    
        .loader {
            display: block;
        }
    }
}
