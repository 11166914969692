.side-bar{
    height: calc(100vh - 65px);
    width: 62px;
    top: 64px;

    background-color: #fff;
    border-right: 1px solid #ced8e0;

    position: sticky;
    display: flex;
    flex-direction: column;
    
    .side-slider{
        display: flex;
        flex-direction: column;
        margin: 0px auto;
        margin-top: 30px;

        .workspace-link {
            user-select: none;
            font-size: 14px;
            font-weight: 600;
            line-height: 38px;
            width: 42px;
            height: 42px;
            margin-bottom: 12px;
            text-align: center;
            text-decoration: none!important;
            vertical-align: middle;
    
            border-radius: 50%;
            background: #e8f0ee;
            border: 2px solid #e8f0ee;
            color: #646A73;
            transition: all .1s ease-in-out;

            &:hover {
                background: #e3dcf0;
                border: 2px solid #e3dcf0;

                color: #5933b0;
            }

            &.active {
                border: 2px solid #5933b0;
                background: transparent;
                color: #383A46;

                &:hover {
                    background: #e3dcf0;
                    border: 2px solid #5933b0;
                    color: #5933b0;
                }
            }

            &:active {
                background-color: rgba(80, 58, 190, 0.2);
                border-color:rgba(80, 58, 190, 0.2);
                transition: background 0s;
            }
        }

        .add-workspace{
            font-size: 22px;
            line-height: 42px;
            width: 42px;
            height: 42px;
            margin-bottom: 12px;
            text-align: center;
            text-decoration: none!important;
            vertical-align: middle;
    
            border-radius: 50%;
            border: 1px solid #e8f0ee;
            background-color: #e8f0ee;
            color: #646A73;
            transition: all .1s ease-in-out;
            cursor: pointer;
            user-select: none;

            &:hover {
                background: #e3dcf0;
                border: 1px solid #e3dcf0;

                color: #5933b0;
            }
        }
    }

    .bottom-extra a{
        color: #5933b0;
        font-size: 24px;
        font-weight: 500;
        line-height: 38px;
    }
}