.navbar.editor-navbar{
    min-height: 64px;
    margin-bottom: 0px;
    box-shadow: 0px 8px 24px rgba(27, 37, 55, 0.04);
    padding-left: 0;
    padding-right: 0;
    border-bottom: 1px solid transparent;
    position: sticky;
    top: 0;
    z-index: 1020;

    .nav-wrap  {
        margin: 0px auto;
        max-width: 1580px;
        padding: 0px 75px;

        .flex-even {
            flex: 1;
        }

        .home-link {
            &:hover {
                text-decoration: none;
            }

            i{
                font-size: 22px;
                line-height: 31px;
            }

            .small-logo {
                height: 32px;
                width: 32px;
                margin-right: 12px;
            }
        
            h1 {
                font-size: 22px;
                font-weight: 600;
                line-height: 32px;
                margin-bottom: 0px;
                color: #3f3f4c;
                
                &:hover {
                    text-decoration: none;
                }
            }
        }

        .pro-tr {
            font-size: 14px;
            height: 33px;
            color: rgb(255, 184, 0);
            line-height: 18px;
            justify-content: center;
        }

        .free-tr {
            font-size: 12px;
            height: 33px;
            color: #646a73;
            line-height: 18px;
            justify-content: center;

            .free-tr-info {
                cursor: pointer;

                color: #796211;
                text-decoration: none;
                background-color: #FFC700;
                border-radius: 4px;
                padding: 2px 6px;
                margin-left: 12px;
                height: 22px;
            }

            .progress {
                overflow: visible;
                height: 8px;
                width: 120px;
                margin-top: 8px;
                margin-bottom: 2px;

                .progress-bar{
                    position: relative;
                    background-color: #5438A8;
                    border-radius: 4px;
                    overflow: visible;

                    &::before{
                        display: inline-block;
                        top: -8px;
                        right: -6px;
                        width: 12px;
                        font-size: 18px;
                        position: absolute;
                        color: #FFC700;
                        font-family: "Font Awesome 5 Pro";
                        font-weight: 900;
                        content: "\f0dd";
                    }
                }
            }
        }
    }

    .btn {
        font-size: 14px;
        font-weight: 500;
        border-radius: 8px;
        
        background-color: #7d55c8;
        border-color: #7d55c8;
    }

    .btn-reverse {
        border-color: #E6EDF1;
        background-color: #FFF;
        color: rgb(156, 165, 175);

        &:focus {
            box-shadow: 0 0 0 0.2rem #704cb340;
        }
    }

    .nav-action-icon {
        cursor: pointer;
    }

    .unstarted {
        .nav-action-icon {
            cursor: auto;
            pointer-events: none;

            i {
                color: #f0ecf8;
            }
        }
    }

    .user-avatar{
        cursor: default;
        height:32px;
        width:32px;
        line-height:32px;
        font-size:14px;
        font-weight:600;
        text-align:center;
        color:#fff;
        background: rgb(178, 194, 208);
        border-radius: 50%;
    }
}