@import "../variables.scss";

$dark-text: #0e1111;
$inactive-text: #999;
$error-text: #E64747;

.sign-in-view {

    .main-logo {
        max-width: 175px;
    }

    .auth-form {
        text-align: center;

        .login-title {
            font-style: normal;
            font-weight: bold;
            font-size: 40px;
            line-height: 72px;
            letter-spacing: 0.005em;
            color: #1B2537;
        }

        .login-details {
            font-weight: normal;
            font-size: 18px;
            line-height: 25px;
            text-align: center;
            color: #646A73;
        }

    }

    .df-btn {
        font-size: 16px;
        text-decoration: none;
        padding: 6px 16px;
        background-color: $main-color;
        border: 1px solid $main-color;
        border-radius: 4px;
        color: #fff;
        outline: none;
        cursor: pointer;

        -webkit-transition: all 100ms cubic-bezier(0.7, 0.01, 1, 1);
        -moz-transition: all 100ms cubic-bezier(0.7, 0.01, 1, 1);
        -o-transition: all 100ms cubic-bezier(0.7, 0.01, 1, 1);
        transition: all 100ms cubic-bezier(0.7, 0.01, 1, 1);
    }

    .df-btn:hover {
        background-color: darken($main-color, 5%);
        border: 1px solid darken($main-color, 5%);
    }

    .df-btn:focus {
        box-shadow: 0 0 0 3px rgba($main-color, 0.5);
    }

    .df-btn:disabled,
    .df-btn[disabled] {
        background-color: #bab6bf;
        border: 1px solid #bab6bf;
    }

    .df-btn-inverse {
        background: transparent;
        border: 1px solid #3a3837;
    }

    .df-btn-inverse:hover {
        color: #fff;
    }

    .auth-switch {
        background-color: #e7d9f7;
        border-color: #e7d9f7;
        color: $main-color;
    }

    .auth-switch:hover {
        background-color: #d7c7e8;
        border-color: #d7c7e8;
        color: $main-color;
    }

    .auth-switch:focus {
        box-shadow: 0 0 0 3px #5f2db985;
    }

    .forgot-password {
        color: #646A73;
        text-decoration: none;
        outline: none;
        font-size: 14px;
    }

    .tos-agree-text {
        margin-top: 18px;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: .3px;
        padding: 10px 30px;
        text-align: left;
        color: $inactive-text;
    }

    .sidelines {
        text-align: center;
        border-bottom: 1px solid #cacaca;
        height: 0.5em;
        line-height: 1;
        font-size: 14px;
        color: #cacaca;
        margin-top: 22px;
    }

    .sidelines span {
        display: inline-block;
        background: #fff;
        padding: 0 0.25em;
    }

    .social-grid {
        margin-top: 22px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 14px;
        row-gap: 8px;
    }

    .login-title {
        margin: 60px -35px 0px -35px;
    }

    .google {
        background-color: #de5246;
        border: 1px solid #de5246;
        color: #fff;

        &.df-btn:hover {
            background-color: #d14a3d;
            border: 1px solid #d14a3d;
        }

        &.df-btn:focus {
            box-shadow: 0 0 0 3px #d14a3d85;
        }
    }

    .facebook {
        background-color: #4267B2;
        border: 1px solid #4267B2;
        color: #fff;

        &.df-btn:hover {
            background-color: #3b5fa8;
            border: 1px solid #3b5fa8;
        }

        &.df-btn:focus {
            box-shadow: 0 0 0 3px #3b5fa885
        }
    }

    .linkedin {
        background-color: #0e76a8;
        border: 1px solid #0e76a8;
        color: #fff;
    }

    .microsoft {
        background-color: #00A4EF;
        border: 1px solid #00A4EF;
        color: #fff;
    }

    .general-error {
        height: 18px;
        font-size: 12px;
        color: $error-text;
    }
}

.side-logo {
    width: 100%;
    height: calc(100vh - 96px);
    display: flex;

    img {
        height: 100%;
        max-width: 95%;
        margin: auto;
    }
}

.form-wrap {
    width: 100%;
    min-height: calc(100vh - 96px);
    display: flex;
}