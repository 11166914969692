block-editor {
    padding: 25px 20px;

    &.rtl content {
        direction: rtl;
        text-align: right;
    }
}

content {
    padding: 4px 8px;
    width: 100%;
    white-space: pre-wrap;
    z-index: 1;
    cursor: text;
    display: block;
    outline: none;
    border: 2px solid transparent;
}

block-editor {

    &.master {

        ali-txt[ali-empty] content::before,
        ali-txt[ali-dummy] content:empty::before {
            content: "Type or paste your text here";
            color: #646a73a3;
        }
    }

    &.related {
        ali-txt[ali-dummy] content:empty::before {
            content: attr(placeholder);
            color: #646a73a3;
        }
    }

    ali-title content:empty::before {
        content: attr(placeholder);
        color: #646a73a3;
    }
}

block-editor ali-title,
block-editor ali-txt {
    border: 1px solid transparent;
}

block-editor {
    &.master {

        ali-title.active,
        ali-txt.active {
            border: 1px solid #F7F9FA;
            background-color: #F7F9FA;
            border-radius: 4px 4px 0 0;
        }
    }

    &.related {

        ali-title.active,
        ali-txt.active {
            border: 1px solid #E6EDF1;
            background-color: #fff;
            border-radius: 4px 4px 0 0;
        }
    }
}

block-editor:not(.view-only).related {

    ali-title.active .extra,
    ali-txt.active .extra {
        display: block;
    }
}

ali-txt {
    position: relative;
    display: flex;
    cursor: text;
}

ali-title {
    position: relative;
    display: flex;
    cursor: text;

    content {
        font-size: 26px;
        font-weight: 500;
        line-height: 30px;
        margin-bottom: 12px;
        margin-top: 12px;
    }
}

ali-txt,
ali-title {
    .extra {
        display: none;
        position: absolute;
        top: 50%;
        left: -65px;
        pointer-events: none;

        .update-translation {
            position: absolute;
            display: flex;
            width: 52px;
            height: 52px;
            line-height: 22px;
            text-align: center;
            top: calc(50% - 26px);
            left: -24px;
            font-size: 14px;
            z-index: 2;
            border: 1px solid #7D55C8;
            border-radius: 50%;
            background-color: #f7f5fa;
            color: #fff;
            pointer-events: all;
            cursor: pointer;

            &:hover {
                box-shadow: 0 0 0 0.2rem #704cb340;
            }

            &:focus {
                box-shadow: 0 0 0 0.2rem #704cb340;
            }

            &:not(.loading) .fa-language,
            &.loading .loader {
                display: block;
            }

            &:not(.loading) .loader,
            &.loading .fa-language {
                display: none;
            }

            .inner-circle {
                width: 40px;
                height: 40px;
                display: flex;
                margin: auto;
                border-radius: 50%;
                background-color: #fff;
            }

            .loader {
                color: #704cb3;
            }

            i {
                height: 40px;
                width: 40px;
                line-height: 40px;
                font-size: 28px;
                border-radius: 50%;
                color: #704cb3;
            }
        }
    }
}

[ ali-selected="true"],
[ ali-selected="true"] content {
    background-color: #f7f5fa !important;
}

[ ali-type="h1"] content {
    margin-bottom: 6px;
}

[ ali-type="h1"] {
    font-size: 1.1rem !important;
}

[ ali-type*="-ul"]::before {
    list-style-type: disc !important;
    display: list-item !important;
    list-style-position: inside !important;
    margin-right: -8px;
    margin-top: 6px;
    content: "";
    padding-left: 15px;
    z-index: 2;
}

[ ali-type="2-ul"] {
    padding-left: 15px !important;
}

[ ali-type="3-ul"] {
    padding-left: 30px !important;
}

/*
    Ordered list styles
*/
[ ali-type="1-ol"] {
    padding-left: 20px;
    counter-increment: ol-1;
}

[ ali-type="2-ol"] {
    padding-left: 30px;
    counter-increment: ol-2;
}

[ ali-type="3-ol"] {
    padding-left: 40px;
    counter-increment: ol-3;
}

[ ali-type*="-ol"]::before {
    padding-top: 6px;
    padding-right: 0;
    margin-right: -6px;
    z-index: 2;
}

/* If previous sibling is not 1/2/3-ol and we do not have no-reset attribute, we reset counter */
.aligner-fragment:not([ ali-type="1-ol"]):not([ ali-type="2-ol"]):not([ ali-type="3-ol"])+[ ali-type="1-ol"]:not([no-reset]) {
    counter-reset: ol-1;
}

/* If previous sibling is not 2/3-ol and we do not have no-reset attribute, we reset counter */
.aligner-fragment:not([ ali-type="2-ol"]):not([ ali-type="3-ol"])+[ ali-type="2-ol"]:not([no-reset]) {
    counter-reset: ol-2;
}

/* If previous sibling is not 3-ol and we do not have no-reset attribute, we reset counter */
.aligner-fragment:not([ ali-type="3-ol"])+[ ali-type="3-ol"]:not([no-reset]) {
    counter-reset: ol-3;
}

[ ali-type="1-ol"]::before {
    content: counter(ol-1) ". ";
}

[ ali-type="2-ol"]::before {
    content: counter(ol-1) "."counter(ol-2) ". ";
}

[ ali-type="3-ol"]::before {
    content: counter(ol-1) "."counter(ol-2) "."counter(ol-3) ". ";
}

.toolbar {
    pointer-events: all;
    padding: 4px;
    background-color: #2c2c2c;

    #link-edit {
        width: -moz-max-content;
        /* Firefox/Gecko */
        width: -webkit-max-content;
        /* Chrome */
        width: max-content;
        top: 6px !important;
        left: -150px !important;
        padding: 4px 6px !important;
        background-color: #2c2c2c;
    }

    #link-edit input {
        outline: none;
        width: 200px;
        border-radius: 4px;
        border: none;
        background-color: #565656;
        color: #FFF;
    }

    #link-edit button {
        padding: 2px 12px;
        height: 100%;
        color: #FFF;
    }
}

.toolbar-row {
    pointer-events: none;
    user-select: none;
    position: absolute;
    display: flex;
    left: 0;
    width: 100%;
    z-index: 2;

    [data-translate] {
        display: none;
        font-size: 1.2rem;
        line-height: 24px;
        color: #fff;
        font-weight: bold;
        border-right: 1px solid #687279;
        text-decoration: none;
    }

    &.multiselect {
        #link-dropdown-toggle {
            display: none;
        }
    }
}

block-editor.related+editor-toolbar {
    .multiselect {
        [data-translate] {
            display: block;
        }
    }
}

.toolbar a {
    cursor: pointer;
}

.toolbar a:hover {
    background-color: #3b3d3b;
}

.toolbar .group-start {
    border-left: 1px solid #687279;
}

.toolbar-row i {
    font-size: 1.2rem;
    padding: 4px;
    color: #fff;
}

editor-toolbar[active-elems*="TYPE-UL"] [data-type="1-ul"] i,
editor-toolbar[active-elems*="TYPE-OL"] [data-type="1-ol"] i,
editor-toolbar[active-elems*="TYPE-H1"] [data-type="h1"] i,
editor-toolbar[active-elems*="ITEM-A"] i.fa-link,
editor-toolbar[active-elems*="ITEM-U"] [data-style="underline"] i,
editor-toolbar[active-elems*="ITEM-B"] [data-style="bold"] i,
editor-toolbar[active-elems*="ITEM-I"] [data-style="italic"] i {
    color: #caaeff;
}