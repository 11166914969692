$dark-text: #0e1111;
$inactive-text: #999;
$buttonmaincolor:#5933b0;

$main-color2: #402f5d;
$main-color:#5933b0;
$error-text: #E64747;
$primary-soft: #EDE3FF;
$base-color: #3a3837;

$google-color: #de5246;
$faceboook-color: #4267B2;

