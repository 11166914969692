.tw-modal {
    .modal-icon {
        position: absolute;
        top: -21px;
        left: calc(50% - 21px);
        height: 42px;
        width: 42px;
        background-color: #F6F8FF;
        text-align: center;
        border-radius: 50%;
        line-height: 42px;
        font-size: 18px;
        color:#5438A8;
    }
    
    .modal-title {
        font-size: 23px;
        line-height: 37px;
        color: #1b2537;
        padding: 0 1rem;
    }

    .footnote {
        color: #646a73;
        padding: 0 1rem;
    }

    .modal-content {
        background: #ffffff;
        border: 1px solid #ced8e0;
        box-sizing: border-box;
        box-shadow: 0px 8px 24px rgba(27, 37, 55, 0.08);
        border-radius: 8px;
        padding: 16px; 
    }

    .input-container {
        position: relative;
        padding: 0 1rem;

        input {
            width: 100%;
            height: 56px;
            background: #f2f4f7;
            border-radius: 8px 8px 0 0;
            box-sizing: border-box;
            font-size: 14px;
            line-height: 26px;
            position: relative;
            border: none;
            border-bottom: solid 2px #7D55C8;
            outline: 0;
            padding: 26px 16px 4px;
        }

        .hint {
            position: absolute;
            left: 32px;
            top: 4px;
            color: #7D55C8;        
        }

        .hint-error {
            position: absolute;
            right: 32px;
            top: 4px;
            color: #E64747;        
        }
    }
}


