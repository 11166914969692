$dark-text: #0e1111;
$inactive-text: #999;

.document-card {
    background-color: #fff;
    height: 220px;
    padding: 14px;
    border-radius: 5px;
    border: 1px solid #C9D3DB;
    cursor: pointer;
    display: flex;
    flex-direction: column;

    .title {
        font-size: 16px;
        font-weight: 600;
        color: #383A46;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis
    }

    .multiline-ellipsis {
        font-size: 14px;
        color: $inactive-text;
        display: block; /* Fallback for non-webkit */
        display: -webkit-box;
        max-height: 100px;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .details {
        display: flex;
        margin-top: auto;
        color: #9CA5AF;
        
        .languages {
            background-color: #f2eef9;
            color: #5933b0;
            border-radius: 4px;
            padding: 0px 6px;
            font-size: 14px;
            line-height: 24px;
        }

        i {
            width: 22px;
            text-align: center;

            &:hover {
                color: #5933b0;
            }
        }
    }
}

.document-card:hover {
    border-color: #b18fff;
    box-shadow: 0 4px 8px rgba(27,37,55,.08);
}