@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600&display=swap');

.new-workspace {
    font-size: 14px;
    line-height: 21px;
    color: #646A73;
    margin: 0px;
    padding: 0px;
    background: #F2F4F7;

    h1{
        font-weight: bold;
        font-size: 40;
        line-height: 72px;
        letter-spacing: 0.005em;
        color: #1B2537;
        padding: 0px 85px;
    }

    .sub-heading{    
        font-size: 18px;
        line-height: 34px;
        text-align: center;
        color: #646A73;
    }

    .frame-box {
        background: #fff;
        border-radius: 8px;
        padding: 50px 45px;
        min-height: 180px;
        max-width: 600px;
        box-shadow: 0 8px 24px rgba(27, 37, 55, 0.08);
    }
        
    .link-cancel {
        /* H5 - Heading 5 */
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 19px;
        line-height: 30px;

        /* identical to box height, or 160% */
        letter-spacing: 0.005em;

        /* dark/grey-dark-2 */
        color: #646A73;
        right: 0;
        position: absolute;
        margin-top: 28px;
        text-decoration: none;
    }

    .link-back{
        /* H5 - Heading 5 */
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 19px;
        line-height: 30px;

        /* identical to box height, or 160% */
        letter-spacing: 0.005em;

        /* dark/grey-dark-2 */
        color: #646A73;
        left: -8%;
        position: absolute;
        margin-top: 28px;
        text-decoration: none;
    }
    .link-cancel:hover, .link-back:hover{
        text-decoration: none;
        color:#7D55C8;
    }

    .text-link{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 26px;

        /* identical to box height, or 186% */
        letter-spacing: 0.005em;

        /* primary-dark-3 */
        color: #704cb3;
    }
    .text-link i{
        font-size: 24px;
        margin-right: 10px;
    }

    .text-link:hover{
        text-decoration: none;
        color:#7D55C8;
    }


    .input-container {
        position: relative;
        padding: 0 1rem;

        input {
            width: 100%;
            height: 70px;
            background: #f2f4f7;
            border-radius: 8px 8px 0 0;
            box-sizing: border-box;
            font-size: 18px;
            line-height: 26px;
            position: relative;
            border: none;
            border-bottom: solid 2px #7D55C8;
            outline: 0;
            padding: 26px 16px 4px;
        }

        .hint {
            position: absolute;
            left: 32px;
            top: 4px;
            color: #7D55C8;        
        }
    }
}
