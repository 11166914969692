.cancel-sub {
    .custom-control-label {
        color: #787d85;
    }

    .custom-control-input:checked ~ .custom-control-label{
        color: #1b2537;
    }

    .custom-control-input:checked ~ .custom-control-label::before{
        border-color: #5438A8;
        background-color: #5438A8;
    }
    .custom-control-input:active~.custom-control-label::before{
        background-color:#5438A8;
    }

    .custom-control-input:focus~.custom-control-label::before{
        box-shadow: 0 0 0 1px #5438A8, 0 0 0 0.2rem rgba(78, 28, 94, 0.25); 
    }
}