$dark-text: #0e1111;
$inactive-text: #999;
$primary-soft: #EDE3FF;

.settings-view {
    background-color: #F5F6F8;
    position: relative;


    .settings.nav-pills .nav-link.active {
        color: #7D55C8;
        background-color: transparent;
        border-bottom: 1px solid #7D55C8;
    }
    
    .settings a {
        color: #6c757c;
        font-size: 18px;
        border-bottom: 1px solid #d3d4d6;
    }
    
    .settings.nav-pills .nav-link {
        border-radius: 0px;
    }

    .settings-view-content {
        display: flex;

        .team-settings a.back-link {
            font-size: 16px;
            color: #9ca5af;
            text-decoration: none;
        }

        .team-settings a.back-link:hover {
            color: #9ca5af;
            text-decoration: none;
        }

        .team-settings p {
            font-size: 14px;
        }

        .team-settings .team-form {
            padding: 34px 48px;
            background-color: #ffffff;
            box-shadow: 0px 8px 24px rgba(27, 37, 55, 0.04);
            border-radius: 10px;
            color: #646a73;
        }

        .team-settings .team-form button.remove-team {
            background-color: transparent;
            border: none;
            color: #646a73;
            height: 34px;
            outline: none;
        }

        .team-settings .team-form button.remove-team img {
            margin-right: 12px;
        }

        .team-settings .team-form .logo-container {
            width: 58px;
            height: 58px;
            background: rgba(210, 217, 226, 0.5);
            border-radius: 12px;
            margin-right: 26px;
        }

        .team-settings .team-form button.upload-logo {
            background-color: transparent;
            border: none;
            color: #1b2537;
            border: 0.5px solid #d8e0eb;
            border-radius: 8px;
            padding: 0 12px;
            height: 34px;
        }

        .team-settings .team-form input[type='text'] {
            border: 0.5px solid #ced8e0;
            box-sizing: border-box;
            border-radius: 8px;
            height: 48px;
            padding: 0 18px;
            width: 100%;
            display: flex;
            align-items: center;
        }

        .team-settings .team-form input[type='text'].with-icon {
            padding-left: 38px;
        }

        .team-settings .team-form .input-with-icon {
            position: relative;
        }

        .team-settings .team-form .input-with-icon input:focus {
            outline: 1px auto #7D55C8;
        }

        .team-settings .team-form .input-with-icon:before {
            content: '';
            width: 20px;
            height: 48px;
            left: 25px;
            background-position: center;
            position: absolute;
            display: block;
            background-repeat: no-repeat;
        }

        .team-settings .team-form .name-row {
            padding-bottom: 30px;
        }

        .team-settings .team-form .input-icons {
            height: 100%;
            position: absolute;
            right: 15px;
        }

        .team-settings .team-form .input-icons img,
        .team-settings .team-form .input-icons button {
            margin-right: 9px;
        }

        .team-settings .team-form .input-icons img img,
        .team-settings .team-form .input-icons button img {
            margin-right: 0;
        }

        .team-settings .team-form .input-icons button {
            border: none;
            background-color: transparent;
        }

    }

    .invite-team-members {
        padding-top: 60px;
        margin-bottom: 50px;
    }

    .header {
        height: 34px;
        margin-bottom: 32px;
    }

    .invite-team-members .member {
        background: #ffffff;
        box-shadow: 0px 8px 24px rgba(27, 37, 55, 0.04);
        border-radius: 10px;
        width: 100%;
        padding: 18px 24px;
        border: solid 1px transparent;
    }

    .invite-team-members .member:hover {
        border: 1px solid #ced8e0;
    }

    .invite-team-members .author .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: solid #ffffff 2px;
        background-color: #f2f4f7;
        color: #7D55C8;
        font-size: 14px;
        text-transform: uppercase;
        margin-right: 18px;
    }

    .invite-team-members .author .avatar img {
        height: 100%;
    }

    .invite-team-members .author .author-name {
        font-size: 14px;
        color: #131a26;
        margin-bottom: 4px;
        line-height: 22px;

        &.pending {
            color: #646a73;
        }
    }

    .invite-team-members .author .email {
        font-size: 13px;
        color: #646a73;
        margin-bottom: 0;
        line-height: 18px;
    }

    .invite-team-members .add-new-team-member .dropdown-toggle {
        background: #ffffff;
        border: 1px solid #9ca5af;
        box-sizing: border-box;
        border-radius: 8px;
        height: 40px;
        padding: 0 8px 0 8px;
        color: #1b2537;
        display: inline-flex;
        align-items: center;
    }

    .invite-team-members .add-new-team-member .dropdown-toggle .add-new {
        display: flex;
        align-items: center;
    }

    .invite-team-members .add-new-team-member .dropdown-toggle:hover {
        text-decoration: none;
    }

    .invite-team-members .add-new-team-member .dropdown-toggle img {
        margin-left: 8px;
    }

    .invite-team-members .add-new-team-member .dropdown-toggle::after {
        display: none;
    }

    .invite-team-members .add-new-team-member .dropdown-toggle i {
        color: #646a73;
    }

    .invite-team-members .add-new-team-member.show .dropdown-toggle {
        border: 1px solid #7D55C8;
    }

    .invite-team-members .add-new-team-member.dropdown {
        position: relative;
    }

    .invite-team-members .add-new-team-member.dropdown .dropdown-menu {
        border: none;
        min-width: 200px;
        box-shadow: 0px 8px 24px rgba(27, 37, 55, 0.08);
        padding: 0;
    }

    .invite-team-members .add-new-team-member.dropdown .dropdown-menu .dropdown-item {
        height: 50px;
        box-sizing: border-box;
        background-color: #fcfdff;
        border-bottom: solid 1px #f2f4f7;
        display: flex;
        align-items: center;
        padding: 0 20px;

        &:hover {
            text-decoration: none;
            background-color: #e5ddf4;
        }
    }

    .invite-team-members .add-new-team-member.dropdown .dropdown-menu .dropdown-item:last-child {
        border-bottom: none;
    }

    .invite-team-members .add-new-team-member.dropdown .dropdown-menu .dropdown-item img {
        margin-right: 20px;
    }
}

.set-password-modal {
    .password-requirements{
        font-size: 12px;
        color: #646a73;
    }
}


.subsciption-tab {
    color: #646a73;
    text-align: center;

    .main-text-title{
        color: rgb(37, 48, 80);
        font-weight: 700;
        margin-top: 32px;
    }

    .main-text {
        font-size: 18px;
        text-align: center;
        margin-bottom: 112px;
    }

    .price {
        color:#5438A8;
        font-weight: 600;
    }

    .subscriptions {

        .limited-offer {
            position: absolute;
            top: -17px;
            left: calc(50% - 60px);

            .offer-percentage{
                background-color: #8EC200;
                color: #fff;
                height: 34px;
                line-height: 34px;
                font-size: 16px;
                font-weight: 700;
                width: 120px;
                border-radius: 34px;
            }

            span {
                color: #8EC200;
                font-size: 12px;
                font-weight: 600;
            }
        }

        .col {
            padding: 34px 48px;
            background-color: #ffffff;
            box-shadow: 0px 8px 24px rgba(27, 37, 55, 0.04);
            border-radius: 20px;

            &.active {
                border: 1px solid #5438A8;
                box-shadow: 0px 0px 34px rgba(84, 56, 168, 0.24);
            }

            button {
                white-space: nowrap;
                border: 0;
                outline: 0;
                display: inline-block;
                height: 40px;
                line-height: 40px;
                padding: 0 14px;
                color: #fff;
                border-radius: 20px;
                border-top-left-radius: 4px;
                font-size: 15px;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 0.025em;
                background-color: #5438A8;
                text-decoration: none;
                -webkit-transition: all 150ms ease;
                transition: all 150ms ease;
                min-width: 210px;
        
                &:focus {
                    outline: none;
                }
        
                .loader {
                    display: none;
                }
            }

            hr {
                margin: 22px -48px;
            }

            ul {
                list-style: none;
                padding: 0px;
                text-align: left;

                li {
                    margin-bottom: 8px;

                    &:before {
                        content: '✓';
                        color: #5438A8;
                        margin-right: 8px;
                    }
                }
            }
        }

        .col:nth-child(2) {
            margin: -24px;
            padding-top: 56px;
            z-index: 1;
        }

        h3 {
            color: #131a26;
            font-weight: 700;
        }
    }
} 