.list-view {
    background-color: #F5F6F8;
    position: relative;
    
    .list-view-content {
        display: flex;

       .grid-container {
            display: grid;
            grid-gap: 21px 30px;
            grid-template-columns: repeat(auto-fill,minmax(190px,1fr));


            .new-document {
                color: #5933b0;
                font-weight: 500;
                text-decoration: none;
                height: 220px;
                user-select: none;
                position: relative;

                .plus {
                    margin-left: 8px;
                    width: 24px;
                    height: 24px;
                    line-height: 24px;
                    font-size: 20px;
                    background: #f2eef9;
                    text-align: center;
                    border-radius: 50%;
                }
                
                &.not-allowed {
                    color: #adb4bc;

                    &:hover {
                        border: 1px solid #C9D3DB;
                    }

                    .plus {
                        background: #f7f7f7;
                    }
                }

                .not-allowed-details {
                    position: absolute;
                    left: 0px;
                    bottom: 0px;
                    padding: 12px;
                    font-size: 12px;
                }
            }

            .loading-card {
                height: 220px;
                user-select: none;
                border: 1px solid #f7f8fa;

                .load-content {                   
                    -webkit-animation: placeHolderSwept 1s infinite linear forwards;
                    -moz-animation: placeHolderSwept 1s infinite linear forwards;
                    -o-animation: placeHolderSwept 1s infinite linear forwards;
                    animation: placeHolderSwept 1s infinite linear forwards;
                    background: #f7f8fa;
                    background-image: linear-gradient(to right, #f7f8fa 0%, #f2f2f3 20%, #f7f8fa 60%, #f7f8fa 100%);
                    background-repeat: no-repeat;
                }

                .line-1 {
                    border-radius: 2px;
                    height: 24px;
                    width: 75%;
                }

                .line-2 {
                    border-radius: 2px;
                    margin-top: 12px;
                    height: 72px;
                }

                .line-3 {
                    border-radius: 2px;
                    margin-top: auto;
                    height: 24px;
                }
            }
       }
    }
}

@-webkit-keyframes placeHolderSwept {
    0% {
      background-position: -400px 0
    }
    100% {
      background-position: 400px 0
    }
  }
  @-moz-keyframes placeHolderSwept {
    0% {
      background-position: -400px 0
    }
    100% {
      background-position: 400px 0
    }
  }
  @-o-keyframes placeHolderSwept {
    0% {
      background-position: -400px 0
    }
    100% {
      background-position: 400px 0
    }
  }
  @keyframes placeHolderSwept {
    0% {
      background-position: -400px 0
    }
    100% {
      background-position: 400px 0
    }
  }