.text-editor {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    header {
        margin: 0px 35px;

        .item {
            font-size: 12px;
            color: #646a73;
            height: 24px;
            line-height: 24px;
            padding: 0px 6px;
            cursor: pointer;
            text-decoration: none;

            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            -webkit-appearance: none;
        }

        .suggested {
            font-size: 12px;
            color: #7d55c8;
            height: 24px;
            line-height: 24px;
        }

        .item.active {
            color: #fff;
            background: #7d55c8;
            border-radius: 4px;
        }

        .item:not(.active):hover {
            color: #7d55c8;
        }

        &.related-header {
            .item.active {
                color: #7d55c8;
                background: #e5ddf4;
                border-radius: 4px;
            }
        } 
    }

    figure {
        position: sticky;
        top: 120px;
    
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;

        img {
            height: 300px;
        }

        figcaption{
            margin: auto;
            color: #646a73;
            max-width: 380px;
            font-size: 14px;

            .small-title {
                font-weight: 600;
            }
        }
    }

    .add-language {
        min-width: 110px;
        text-align: center;
        background-color: #81C855;
        color: #fff;
        border-radius: 4px;
        font-size: 12px;
        height: 24px;
        line-height: 24px;
        cursor: pointer;

        &:hover {
            color: #fff;
        }
    }

    .delete-language:hover {
        color: #7d55c8;
    }

    &.doc-not-created .add-language,
    &.doc-not-created .delete-language {
        display: none;
    }

    .unstarted {
        user-select: none;
        pointer-events: none;
    }

    .languageListContainer {
        width: 200px;
        z-index: 4!important;
        position: absolute;
        top: 60px;
        right: 40px;
        flex-wrap: wrap;
        box-shadow: rgba(27, 37, 55, 0.04) 0px 8px 24px;
        max-height: 320px;
        overflow: auto;
        background-color: #ffffff;
        z-index: 1;
        height: 100%;
        align-items: flex-start;
        align-content: flex-start; 

        &#master-language-select {
            left: 75px;
        }

        .item {
            width: 100%;
            margin: 10px 0px;
            box-sizing: border-box; 
        }

        &.show {
            display: flex; 
        }

        &.collapsing {
            transition: none; 
        }

        .dropdown-item-language {
            height: 34px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            padding: 0 20px;
            font-size: 14px;
            color: #1b2537; 
            cursor: pointer;

            &:hover {
                text-decoration: none;
                background-color: #e5ddf4; 
            }

            &:last-child {
                border-bottom: none; 
            }

            img {
                margin-right: 16px; 
            }
        }

        form {
            width: 100%; 
            position: sticky;
            top: 0;
        }

        input[type='search'] {
            display: block;
            width: 100%;
            height: 42px;
            border: none;
            outline: none;
            padding: 0 20px;
            background: #f2f4f7;
            color: #9ca5af;
            font-size: 14px;
        }
    }
}

.text-editor {
    .language-suggestion  {
        color: #9ca5af;

        .add-translation-title {
            font-size: 36px;
            color: #dad9e7;
            margin-bottom: 12px;
        }
    
        .suggested-lang-list {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
            justify-content: center;
            
            .suggested-lang {
                text-align: center;
                font-weight: 400;
                font-family: arial;
                position: relative;
                border-radius:4px;
                color: #dad9e7;
                padding: 3px 9px;
                margin: 6px;
                font-size: 22px;
                cursor: pointer;
                flex-grow: 0;
                box-shadow: 2px 2px 6px 2px rgba(27, 37, 55, 0.06);
    
                &:hover {
                    color: #7d55c8
                }
              }
        }
    

    }
    
      
      
}