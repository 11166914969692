.over-limit-modal {
    .modal-content {
        padding: 34px 72px;
    }

    .modal-icon {
        position: absolute;
        top: -21px;
        left: calc(50% - 21px);
        height: 42px;
        width: 42px;
        background-color: #F6F8FF;
        text-align: center;
        border-radius: 50%;
        line-height: 42px;
        font-size: 18px;
        color:#5438A8;
    }

    .title {
        font-size: 22px;
        color: #5438A8;
        text-align: center;
        font-weight: 600;
        margin-top: 6px;
        margin-bottom: 6px;
    }

    .title-details {
        font-size: 16px;
        text-align: left;
        color: #747786;
        margin-bottom: 22px;
    }

    button {
        white-space: nowrap;
        border: 0;
        outline: 0;
        display: inline-block;
        min-height: 40px;
        line-height: 20px;
        padding: 4px 14px;
        margin: 0 12px;
        color: #fff;
        border-radius: 20px;
        border-top-left-radius: 4px;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.025em;
        background-color: #5438A8;
        text-decoration: none;
        -webkit-transition: all 150ms ease;
        transition: all 150ms ease;
        min-width: 160px;

        &:focus {
            outline: none;
        }

        .loader {
            display: none;
        }
    }

    button.subsribe-btn {
        background-color: #8EC200;

        .off-50 {
            display: block;
            margin: 0;
            font-size: 14px;
            font-weight: 500;
        }
    }

    button.cancel-btn {
        background-color: #747786;
    }
}