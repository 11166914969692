.toasts-container {
    bottom: 25px;
    right: 25px;
    position: fixed;
    z-index: 1041;
    pointer-events: all;

    .toasts-list {
        .tw-toast {
            margin: 12px;
            bottom: 25px;
            right: 25px;
            padding: 10px 16px;
            background: #fff;
            box-shadow: 0px 4px 8px rgba(27, 37, 55, 0.08);
            border-radius: 6px;
            border: none;
            width: 285px;
            color: #1b2537;

            .toast-body{
                padding: 0px;
            }

            .fa-error {
                color:#E64747;
                font-size: 2em;
                padding-right: 16px;
            }
            
            .fa-success {
                color:#4BB543;
                font-size: 2em;
                padding-right: 16px;
            }
        }   
    }
}
