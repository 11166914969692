.default-navbar {
    min-height: 64px;
    margin-bottom: 0px;
    box-shadow: 0px 8px 24px rgba(27, 37, 55, 0.04);
    border-bottom: 1px solid #CED8E0;

    position: sticky;
    top: 0;
    z-index: 1020;

    .nav-wrap {
        margin: 0px 60px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .main-logo {
            max-width: 175px;
            height: 32px;
        }

        .user-panel {
            a {
                color: #5933b0;
                text-decoration: none;
            }

            .account-detail {
                font-size: 12px !important;
                line-height: 18px !important;
                color: #9ca5af !important;
            }

            .avatar {
                height: 48px;
                width: 48px;
                border-radius: 50%;
                margin: 0 10px;
                background-color: #e8f0ee;
                font-size: 18px;
            }


            .dropdown-toggle {
                outline: none;
            }

            .dropdown-toggle::after {
                margin-top: auto;
                margin-bottom: auto;
            }
        }

        .dropdown .dropdown-menu {
            top: 55px;
            border: none;
            width: 100%;
            box-shadow: 0px 8px 24px rgba(27, 37, 55, 0.08);
            padding: 0;
            outline: none;
        }

        .dropdown .dropdown-menu .dropdown-item {
            height: 50px;
            box-sizing: border-box;
            background-color: #fcfdff;
            border-bottom: solid 1px #f2f4f7;
            display: flex;
            align-items: center;
            padding: 0 20px;
            color: #1b2537;
        }

        .dropdown .dropdown-menu .dropdown-item:last-child {
            border-bottom: none;
        }
    }
}