.dual-text-editor {
    
    background: linear-gradient(90deg, #fff 50%, #FBFCFC 50%);

    .allowed-selection-area {
        overflow: scroll;
        height: calc(100vh - 64px);
    }

    .pane-main-container {
        min-height: 100%;
        width: 100%;
        max-width: 1580px;
        margin: auto;

        .pane-container {
            padding: 30px 40px;
            display: flex;
        }
    }
}

.selection-area {
    background-color: #efedf66e;
    border: 1px solid #ebe8f0;
}

::selection{
    background-color: #ebe8f0;
}