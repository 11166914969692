.subscription-modal{
    .modal-content {
        padding: 34px 72px;
    }

    .title {
        font-size: 22px;
        color: #5438A8;
        text-align: center;
        font-weight: 600;
        margin-top: 6px;
        margin-bottom: 22px;
    }

    .modal-icon {
        position: absolute;
        top: -21px;
        left: calc(50% - 21px);
        height: 42px;
        width: 42px;
        background-color: #F6F8FF;
        text-align: center;
        border-radius: 50%;
        line-height: 42px;
        font-size: 18px;
        color:#5438A8;
    }

    .price {
        color:#5438A8;
        font-weight: 600;
    }

    .choice {
        position: relative;
        display: flex;
        font-size: 12px;
        line-height: 20px;
        cursor: pointer;

        padding: 10px 18px;
        margin-bottom: 12px;
        border: 1px solid #e7eaf8;
        border-radius: 8px;
        border-top-left-radius: 25px;

        .price-line {
            color: #747786;
        }
        .check-mark {
            margin-left: auto;
            font-size: 20px;
            color: #8EC200
        }

        &.selected {
            border-color: #5438A8;
            box-shadow: 0px 4px 14px rgba(84, 56, 168, 0.08);
        }
    }

    .billing {
        font-size: 12px;
    }

    .grey-info {
        text-align: center;
        padding: 8px;
        margin: 16px 0;
        color: #747786;
        background-color: #f6f8ff;
        font-size: 12px;
        font-weight: 500;
    }

    /* Stripe  form */
    form {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        color: #747786;
    }

    input:not(#agree), .StripeElement {
        display: block;
        height: 38px;
        margin: 2px 0 8px 0;
        width: 100%;
        padding: 10px 14px;
        font-size: 14px;
        font-family: "Source Code Pro", monospace;
        border: 1px solid #daddeb;
        outline: 0;
        border-radius: 4px;
        background: white;
        color: #424770;
      }
      
    input::placeholder {
        color: #daddeb;
    }
      
    input:focus,
      .StripeElement--focus {
        border-color: #5438A8;
        -webkit-transition: all 150ms ease;
        transition: all 150ms ease;
    }
      
    button {
        white-space: nowrap;
        border: 0;
        outline: 0;
        display: inline-block;
        height: 40px;
        line-height: 40px;
        padding: 0 14px;
        color: #fff;
        border-radius: 20px;
        border-top-left-radius: 4px;
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.025em;
        background-color: #5438A8;
        text-decoration: none;
        -webkit-transition: all 150ms ease;
        transition: all 150ms ease;
        width: 210px;

        &:focus {
            outline: none;
        }

        .loader {
            display: none;
        }
    }

    button:disabled {
        background-color: #8b78c4; 

        .pay-text {
            display: none;
        }

        .loader {
            display: block;
        }
    }

    .agreement-link{
        color: #747786;
        text-decoration: underline;
    }

    .sale-tag {
        position: absolute;
        top: -9px;
        left: 20px;
        background-color: #8EC200;
        color: #fff;
        height: 18px;
        line-height: 18px;
        font-size: 10px;
        font-weight: 700;
        padding: 0px 8px;
        border-radius: 9px;
    }
}